// extracted by mini-css-extract-plugin
export var alumniInfo = "AlumniCard-module--alumni-info--61DTf";
export var availForHireLink = "AlumniCard-module--avail-for-hire-link--VCfRX";
export var beforeJuno = "AlumniCard-module--before-juno--Ygly2";
export var card = "AlumniCard-module--card--aHWJi";
export var currently = "AlumniCard-module--currently--oPNhO";
export var headshot = "AlumniCard-module--headshot--7r1mv";
export var name = "AlumniCard-module--name--k2FPW";
export var nameplate = "AlumniCard-module--nameplate--fk-MU";
export var quote = "AlumniCard-module--quote--Pd1WM";
export var season = "AlumniCard-module--season--3K9Oq";
export var social = "AlumniCard-module--social--FGr2-";
export var studentBio = "AlumniCard-module--student-bio--jjU3W";
export var studentStory = "AlumniCard-module--student-story--KU-FF";